import React from "react";
import styled, { css } from "styled-components";
import { graphql, Link as GatsbyLink } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { ReactComponent as LogoOutline } from "../svg/logo-outline.svg";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { SiteTitle } from "../components/pageTitle";
import { sectionHeading, playfairStack } from "../tokens/typography";
import HomePageTrack from "../components/homePageTrack";
import Hero from "../components/hero";
import GatsbyImage from "gatsby-image";
import { Button } from "../components/button";
import { hideVisually, transparentize } from "polished";
import { fancyLinkStyles } from "../tokens/utils";
import RichText from "../components/richText";
import Gantt from "../components/gantt";

const Intro = styled.div`
  max-width: 36ch;
  margin-top: 4.5rem;
  font-size: 1.2em;
  line-height: 1.3;

  @media (min-width: 42em) {
    font-size: 1.4em;
  }

  & > ::first-letter {
    font-family: ${playfairStack};
    font-size: 2em;
    font-weight: 900;
    line-height: 1.1;
  }
`;

const IndexPage = ({ data }) => {
  const { pageContent } = data;
  const { childContent } = pageContent;
  const filteredPageContent =
    childContent &&
    childContent
      .filter((item) => item.__typename === "ContentfulPage")
      .filter((item) => item.title === "Curators");

  return (
    <Layout
      hero={
        <HiddenOverflow>
          <OutlineLogo />
          <Hero>
            <IwdPageTitle>{pageContent.title}</IwdPageTitle>
            <Intro>
              <RichText>{renderRichText(pageContent.body)}</RichText>
            </Intro>
          </Hero>
        </HiddenOverflow>
      }
    >
      <SEO title="Home" />
      {filteredPageContent.map((childItem) => {
        const {
          id,
          title,
          summary = null,
          body,
          slug,
          pageLinkText,
          curators = null,
        } = childItem;
        let introText = summary || body;
        const filteredTracks = [];
        const filteredCurators =
          curators &&
          curators.filter((item) => item.__typename === "ContentfulCurator");
        return (
          <Section key={id}>
            <SectionTitle>{title}</SectionTitle>
            <SectionIntro>
              <RichText>{renderRichText(introText)}</RichText>
            </SectionIntro>
            {filteredTracks &&
              filteredTracks.length > 0 &&
              filteredTracks.map(
                ({
                  id,
                  alternativeName = "",
                  descriptiveTitle,
                  trackName,
                  shortDescription,
                  page,
                  slug,
                }) => {
                  const trackNameForCss = alternativeName.toLowerCase();
                  return (
                    <HomePageTrack
                      key={id}
                      trackNameForCss={trackNameForCss}
                      shortDescription={shortDescription}
                      alternativeName={alternativeName}
                      descriptiveTitle={descriptiveTitle}
                      trackName={trackName}
                      page={page}
                      slug={slug}
                    />
                  );
                }
              )}
            {filteredCurators && filteredCurators.length > 0 && (
              <CuratorsLinks>
                {filteredCurators.map((curator) => {
                  const { id, logoOnDark = null, slug, page, name } = curator;
                  const pageSlug = page && page[0].slug;
                  return (
                    <CuratorLink key={id} to={`/${pageSlug}/${slug}/`}>
                      {logoOnDark && <CuratorLogo fluid={logoOnDark.fluid} />}
                      <HiddenName>{name}</HiddenName>
                    </CuratorLink>
                  );
                })}
              </CuratorsLinks>
            )}
            {pageLinkText && (
              <Button as={Link} to={`/${slug}/`}>
                {pageLinkText}
              </Button>
            )}
          </Section>
        );
      })}
    </Layout>
  );
};

const HiddenOverflow = styled.div`
  position: relative;
  overflow: hidden;
`;

const OutlineLogo = styled(LogoOutline)`
  position: absolute;
  width: 60vw;
  max-width: 1000px;
  top: 2rem;
  right: -4rem;
  fill: none !important;
  stroke: ${({ theme }) => theme.palette.white};
  stroke-width: 2;
  z-index: -1;

  @media (min-width: 32em) {
    top: 5rem;
    right: -2rem;
  }

  @media (min-width: 42em) {
    stroke-width: 1;
    right: 0;
  }
`;

const HeroDate = styled.span`
  display: inline-block;

  @media (min-width: 32em) {
    font-size: 1.2em;
  }

  @media (min-width: 42em) {
    font-size: 1.6em;
  }
`;

const IwdPageTitle = styled(SiteTitle)`
  max-width: 8em;
  margin-top: 0;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 2rem;
`;

const SectionTitle = styled.h2`
  ${sectionHeading};
`;

const SectionIntro = styled.div`
  max-width: 50ch;
  margin-top: 1.5em;
  font-size: 1.2em;

  blockquote {
    margin-top: 0;
    margin-bottom: -1rem;
    margin-left: 0;
    padding: 0;
    border: none;
    font-size: 1.6em;
    background-color: transparent;

    @media (min-width: 32em) {
      font-size: 2em;
    }

    @media (min-width: 42em) {
      font-size: 2.5em;
    }

    @media (min-width: 52em) {
      font-size: 3em;
    }

    > p {
      margin: 0;

      &:first-child,
      &:last-child {
        &::before,
        &::after {
          content: none;
        }
      }
    }
  }
`;

const CuratorsLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin-top: 2rem;
  margin-right: -1rem;
  margin-left: -1rem;

  @media (min-width: 32em) {
    margin-right: -2rem;
    margin-left: -2rem;
  }
`;

const CuratorLink = styled(GatsbyLink)`
  padding: 1rem 2rem;
`;

const curatorLogoHeight = 5;

const curatorLogoDimensions = css`
  width: ${({ fluid }) => curatorLogoHeight * fluid.aspectRatio}rem;
  height: ${curatorLogoHeight}rem;
`;

const CuratorLogo = styled(GatsbyImage)`
  ${curatorLogoDimensions};
  display: block;
`;

const HiddenName = styled.span`
  ${hideVisually}
`;

const Link = styled(GatsbyLink)`
  margin-top: 2rem;
  margin-right: 1rem;
  text-decoration: none;
`;

export const pageQuery = graphql`
  query HomePage {
    pageContent: contentfulHomePage(
      contentful_id: { eq: "5QlGrbhYDLycnlP38j1TEH" }
    ) {
      id
      title
      body {
        raw
      }
      childContent {
        ... on ContentfulPage {
          __typename
          id
          title
          slug
          pageTemplate
          summary {
            raw
          }
          body {
            raw
          }
          pageLinkText
          tracks: childContent {
            ... on ContentfulTrack {
              __typename
              id
              descriptiveTitle
              alternativeName
              trackName: name
              slug
              page {
                slug
              }
              id
              shortDescription {
                raw
              }
            }
          }
          curators: childContent {
            ... on ContentfulCurator {
              __typename
              id
              name
              slug
              logoOnDark {
                fluid(maxWidth: 500) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              page {
                slug
              }
            }
          }
          collaborators: childContent {
            ... on ContentfulCollaborator {
              __typename
              id
              name
              slug
              page {
                slug
              }
            }
            ... on ContentfulSpeaker {
              __typename
              id
              name
              slug
              page {
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
